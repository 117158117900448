// extracted by mini-css-extract-plugin
export var body_old = "header-module--body_old--6aHE8";
export var btn = "header-module--btn---2onR";
export var clock = "header-module--clock--m0XLJ";
export var container = "header-module--container--1LzWj";
export var header_row = "header-module--header_row--qzrKz";
export var header_text = "header-module--header_text--8JYaT";
export var headerspan = "header-module--headerspan--lEjOR";
export var headline_old = "header-module--headline_old--McIXB";
export var link_to_updates_div = "header-module--link_to_updates_div--4wAxH";
export var mobile = "header-module--mobile--p2r91";
export var navbar_container = "header-module--navbar_container--WTnaK";
export var placeholder = "header-module--placeholder--F+msZ";
export var quoted_body = "header-module--quoted_body--UAw5K";
export var quoted_container = "header-module--quoted_container--XRP8h";
export var sep = "header-module--sep--VE3z5";
export var time_old = "header-module--time_old--ZJhAF";
export var time_quoted = "header-module--time_quoted--2FFFj";
export var update_container = "header-module--update_container--+Tdj9";
export var web = "header-module--web--WpqQQ";